import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewContainerRef,
  ViewEncapsulation,
} from "@angular/core";
import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { Subject, takeUntil } from "rxjs";
import { HeaderTextService } from "./header-text.service";
import { Router } from '@angular/router';
import { TitleType } from "./header-text.types";

@Component({
  selector: "header-text",
  templateUrl: "./header-text.component.html",
  encapsulation: ViewEncapsulation.None,
  exportAs: "headerText",
  standalone: true
})
export class HeaderTextComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _headerTextService: HeaderTextService,
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
  }

  getTitulo() {
    let titulo = this._headerTextService.getTitulo();
    let nombreEvento = this._headerTextService.getNombre();

    /**
     * Resumen de pagos se toma el nombre de evento y id para setearlo
     */
    // Proyectos
    if (titulo && titulo.includes("/proyectos/rendiciones")) {
      const baseTitle = TitleType.find((title) => title.path.includes("/proyectos/rendiciones")).text;
      return nombreEvento ? `${baseTitle} - ${nombreEvento}` : baseTitle;
    }

    if (titulo && titulo.includes("/proyectos/rendicion/proyecto")) {
      const baseTitle = TitleType.find((title) => title.path.includes("/proyectos/rendicion/proyecto")).text;
      return nombreEvento ? `${baseTitle} - ${nombreEvento}` : baseTitle;
    }
    // Eventos
    if (titulo && titulo.includes("/eventos/resumen-pagos")) {
      const baseTitle = TitleType.find((title) => title.path.includes("/eventos/resumen-pagos")).text;
      return nombreEvento ? `${baseTitle} - ${nombreEvento}` : baseTitle;
    }
    
    if (titulo && titulo.includes("/eventos/configuracion-eventos")) {
      const baseTitle = TitleType.find((title) => title.path.includes("/eventos/configuracion-eventos")).text;
      return nombreEvento ? `${baseTitle} - ${nombreEvento}` : baseTitle;
    }

    if (titulo && titulo.includes("/eventos/integrantes-evento")) {
      const baseTitle = TitleType.find((title) => title.path.includes("/eventos/integrantes-evento")).text;
      return nombreEvento ? `${baseTitle} - ${nombreEvento}` : baseTitle;
    }

    if (titulo && titulo.includes("/eventos/papelera-evento")) {
      const baseTitle = TitleType.find((title) => title.path.includes("/eventos/papelera-evento")).text;
      return nombreEvento ? `${baseTitle} - ${nombreEvento}` : baseTitle;
    }

    if (titulo && titulo.includes("/inscripciones/encuesta-medica")) {
      const baseTitle = TitleType.find((title) => title.path === "/inscripciones/encuesta-medica")?.text;
      return nombreEvento ? `${baseTitle}` : baseTitle;
    }

    if (titulo && titulo.includes("/inscripciones")) {
      const baseTitle = TitleType.find((title) => title.path.includes("/inscripciones")).text;
      return nombreEvento ? `${baseTitle}` : baseTitle;
    }

    if (titulo && titulo.includes("/indicadores")) {
      const baseTitle = TitleType.find((title) => title.path.includes("/indicadores")).text;
      return nombreEvento ? `${baseTitle}` : baseTitle;
    }

    return TitleType.find((title) => title.path === titulo)?.text || 'Tu Registro';
  }
  
  getPath() {
    return this._headerTextService.getTitulo() != "/" ? this._headerTextService.getTitulo() : "";
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

}
