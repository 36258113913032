
export const TitleType = [
  // Publico
  { icon: "", text: "Inicio", path: "/home" },
  { icon: "", text: "Seleccion de Roles", path: "/seleccion-roles" },
  { icon: "", text: "Mi Perfil", path: "/mi-perfil" },
  // Administracion
  { icon: "", text: "Empresa", path: "/empresas" },
  { icon: "", text: "Usuarios", path: "/usuarios" },
  { icon: "", text: "Cuentas MP", path: "/mercado_pago" },
  // Proyectos
  { icon: "", text: "Proyectos", path: "/proyectos"},
  { icon: "", text: "Rendición", path: "/proyectos/rendicion/proyecto" },  
  { icon: "", text: "Rendiciones", path: "/proyectos/rendiciones" },  
  // Eventos
  { icon: "", text: "Eventos", path: "/eventos"},  
  { icon: "", text: "Alta Eventos", path: "/eventos/alta-eventos"},  
  { icon: "", text: "Configuración Evento", path: "/eventos/configuracion-eventos"},
  { icon: "", text: "Resumen Pagos", path: "/eventos/resumen-pagos" },
  { icon: "", text: "Integrantes Evento", path: "/eventos/integrantes-evento"},
  { icon: "", text: "Papelera", path: "/eventos/papelera-evento"},
  // Inscripciones
  { icon: "", text: "Inscripciones", path: "/inscripciones" }, 
  { icon: "", text: "Encuesta Médica", path: "/inscripciones/encuesta-medica" },
  // Facturas
  { icon: "", text: "Facturas Emitidas", path: "/facturas"},
  // Indicadores
  { icon: "", text: "Indicadores", path: "/indicadores"}
];
