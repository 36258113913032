import { CanActivateChildFn, CanActivateFn, Router, UrlTree } from "@angular/router";
import { AuthService } from "../auth.service";
import { of, switchMap, from, take } from "rxjs";
import { inject } from "@angular/core";
import { NavigationService } from "@core/navigation/navigation.service";

export const PermisoGuard: CanActivateFn | CanActivateChildFn | UrlTree = (route, state) => {
    const router: Router = inject(Router);
    const authService = inject(AuthService);
    const navigationService = inject(NavigationService);


    // Función para combinar la ruta estática y los parámetros dinámicos
    const getFullPath = (state): string => {
        return state.url.replace(/^\/+/, '');
    };

    return navigationService.menuLoaded$.pipe(
        take(1),
        switchMap((loaded) => {
            if (!loaded) {
                return from(navigationService.getMenus()).pipe(
                    switchMap(() => {
                        const fullPath = getFullPath(state);
                        return authService.verificarRutasPermitidas(fullPath);
                    })
                );
            }
            const fullPath = getFullPath(state);
            return authService.verificarRutasPermitidas(fullPath);
        }),
        switchMap((routeAllow) => {
            if (!routeAllow) {
                const urlTree = router.createUrlTree(['/acceso-denegado']);
                return of(urlTree);
            } else {
                return of(true);
            }
        })
    );
};